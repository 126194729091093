import React from "react"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import IframeResizer from "iframe-resizer-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "aos/dist/aos.css"

import diamondPointTechnology from "../images/diamond-point-technology.png"
import unifinePentips from "../images/unifine-pentips.png"
import antiCoring from "../images/anti-coring.png"
import withOptiflow from "../images/with-optiflow.png"
import withoutOptiflow from "../images/without-optiflow.png"
import optiflowTechnology from "../images/optiflow-technology.png"
import up4 from "../images/up-4mm.png"
import up5 from "../images/up-5mm.png"
import up6 from "../images/up-6mm.png"
import up8 from "../images/up-8mm.png"
import up12 from "../images/up-12mm.png"

import penNeedlesSyringeOrderForm from "../documents/pen-needles-syringe-order-form.pdf"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
  const AOS = require("aos")
  AOS.init()
}

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <div id="home-section" className="hero-cover" data-aos="fade">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="text-uppercase text-light" data-aos="fade-up">
                Designed For a Comfortable Injection Experience, Every Time
              </h1>
              <div
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate mt-3"
              >
                <Link
                  to="/#order-samples-section"
                  className="btn btn-primary btn-lg mr-2 mb-2"
                >
                  Order Samples
                </Link>
                <Link
                  to="/#problem-section"
                  className="d-none d-md-inline-block btn btn-secondary btn-lg mr-2 mb-2"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="ndss-circle d-none d-md-table" data-aos="fade">
          <p>Available on the NDSS</p>
        </div>
        <a
          href="#problem-section"
          className="mouse smoothscroll d-none d-md-block"
        >
          <span className="mouse-icon">
            <span className="mouse-wheel"></span>
          </span>
        </a>
      </div>

      <div id="problem-section" className="bg-primary pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center text-light text-center">
            <div className="col-sm-12 col-md-10">
              <h2 data-aos="fade">
                Anybody who needs to regularly inject insulin knows how much of
                a daily torment it can be
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div id="solution-section" className="pt-5 pb-2 bg-light">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-sm-12 col-md-8">
              <h2 data-aos="fade">
                With the right insulin pen needle it doesn’t have to be a pain!
              </h2>
            </div>
            <div className="col-sm-12 col-md-10">
              <p className="lead" data-aos="fade" data-aos-delay="300">
                Unifine® Pentips® are designed to deliver{" "}
                <u>improved comfort</u>, by focusing on four key areas&hellip;
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="diamond-point-section" className="pt-5 pb-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <img
                src={diamondPointTechnology}
                alt="Diamond Point Technology"
                className="float-md-right img-fluid mb-2"
                data-aos="fade-left"
                data-aos-duration="600"
                data-aos-easing="ease-in-sine"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                src={unifinePentips}
                alt="Sharp needles, improved comfort"
                className="pr-3 img-fluid"
                style={{ transform: "scaleX(-1)" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 data-aos="fade-up" data-aos-duration="1000">
                <span className="text-primary">Razor Sharp</span> Needles
              </h3>
              <p className="pl-3" data-aos="fade-up" data-aos-duration="1000">
                Triple sharpened needle tips and bevelled edges allow the needle
                to pierce the skin quickly, for a seriously comfortable
                injection experience.<sup>1</sup>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="optiflow-section" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 p-2 p-md-0">
              <img
                src={optiflowTechnology}
                alt="Optiflow Technology"
                className="img-fluid"
                data-aos="fade"
              />
            </div>
            <div className="col-md-9">
              <h3 className="pl-md-3" data-aos="fade">
                Better <span className="text-primary">Medication Delivery</span>
              </h3>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-6 d-flex align-items-center">
              <p data-aos="fade">
                Thin wall technology designed to improved drug flow and lower
                delivery force, making the injection process much more
                comfortable and easier.<sup>1</sup>
              </p>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <figure className="figure text-center">
                <img
                  src={withOptiflow}
                  alt="Better drug flow means faster injections"
                  className="figure-img img-fluid rounded pl-4 pr-4 pb-2 p-md-0"
                  alt="Unifine Pentips with optiflow technology."
                />
                <figcaption className="figure-caption small">
                  With Optiflow
                </figcaption>
              </figure>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <figure className="figure text-center">
                <img
                  src={withoutOptiflow}
                  alt="Thick walls mean longer injection times and more pain"
                  className="figure-img img-fluid rounded pl-4 pr-4 pb-2 p-md-0"
                  alt="Insulin pentip without optiflow technology."
                />
                <figcaption className="figure-caption small">
                  Without Optiflow
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div id="anti-coring-section" className="pt-2 pb-5 pt-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-2 offset-md-1 d-flex align-items-center">
              <img
                src={antiCoring}
                alt="Reduce the potiential to inject fragments"
                className="img-fluid pl-4 pr-4 pb-2 p-md-0"
                data-aos="fade-right"
                data-aos-duration="2000"
              />
            </div>
            <div className="col-md-3 offset-lg-1 d-flex align-items-center">
              <h3
                className="h2"
                data-aos="fade"
                data-aos-delay="300"
                data-aos-duration="1500"
              >
                <span className="text-primary">Failsafe</span> Design
              </h3>
            </div>
            <div className="col-md-4 offset-md-1 offset-lg-0 d-flex align-items-center">
              <p data-aos="fade" data-aos-delay="600" data-aos-duration="1500">
                The anti-coring treatment reduces the risk of rubber particle
                contamination when screwing into a pen cartridge.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="low-glide-section" className="pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h3 data-aos="fade-up" data-aos-duration="1500">
                <span className="text-primary">Low Glide</span> Technology
              </h3>
              <p
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1500"
              >
                Advanced silicone technology allows the needle to pass through
                the skin smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="unnamed-section" className="bg-primary text-white pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-8 text-center">
              <h2 data-aos="fade">Made for Everyone</h2>
              <p className="lead white" data-aos="fade">
                Unifine
                <sup>®</sup> Pentips
                <sup>®</sup> is available on the NDSS in five needle lengths –
                4mm, 5mm, 6mm, 8mm and 12 mm –{` `}
                <em>
                  and is fully compatible with your insulin injection pen.
                  <sup>2</sup>
                </em>
              </p>
            </div>
          </div>
          <div className="row pt-1 justify-content-center">
            <div
              className="col-md-3 col-lg-2 m-1 pb-2 d-flex align-items-end"
              style={{ borderRadius: `15px`, backgroundColor: `#00a652` }}
            >
              <div className="text-center">
                <img
                  src={up4}
                  alt="Unifine Pentips 4mm"
                  className="img-fluid pt-2 pr-4 pl-4 p-md-2"
                  data-aos="fade"
                />
                4&thinsp;mm
                <br />
                <span className="small">NDSS #314</span>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-2 m-1 pb-2 d-flex align-items-end"
              style={{ borderRadius: `15px`, backgroundColor: `#a09fa2` }}
            >
              <div className="text-center">
                <img
                  src={up5}
                  alt="Unifine Pentips 5mm"
                  className="img-fluid pt-2 pr-4 pl-4 p-md-2"
                  data-aos="fade"
                />
                5&thinsp;mm
                <br />
                <span className="small">NDSS #235</span>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-2 m-1 pb-2 d-flex align-items-end"
              style={{ borderRadius: `15px`, backgroundColor: `#ab87be` }}
            >
              <div className="text-center">
                <img
                  src={up6}
                  alt="Unifine Pentips 6mm"
                  className="img-fluid pt-2 pr-4 pl-4 p-md-2"
                  data-aos="fade"
                />
                6&thinsp;mm
                <br />
                <span className="small">NDSS #213</span>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-2 m-1 pb-2 d-flex align-items-end"
              style={{ borderRadius: `15px`, backgroundColor: `#60c9d7` }}
            >
              <div className="text-center">
                <img
                  src={up8}
                  alt="Unifine Pentips 8mm"
                  className="img-fluid pt-2 pr-4 pl-4 p-md-2"
                  data-aos="fade"
                />
                8&thinsp;mm
                <br />
                <span className="small">NDSS #214</span>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-2 m-1 pb-2 d-flex align-items-end"
              style={{ borderRadius: `15px`, backgroundColor: `#e74964` }}
            >
              <div className="text-center">
                <img
                  src={up12}
                  alt="Unifine Pentips 12mm"
                  className="img-fluid pt-2 pr-4 pl-4 p-md-2"
                  data-aos="fade"
                />
                12&thinsp;mm
                <br />
                <span className="small">NDSS #215</span>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-12 text-center">
              <a
                href="#order-samples-section"
                className="btn btn-light btn-lg mt-2 mb-1"
              >
                Order Samples
              </a>
              <br />
              <a
                href={penNeedlesSyringeOrderForm}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: `#ffffff`,
                  textDecoration: `underline`,
                  fontWeight: `200`,
                }}
              >
                Download NDSS Order Form
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="unnamed-section" className="pt-5 pb-5 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h2 className="text-center pb-2" data-aos="fade">
                Hear what people have to say&hellip;
              </h2>
              <Carousel>
                <Carousel.Item>
                  <Carousel.Caption>
                    <p className="lead text-dark pb-2">
                      &ldquo;
                      <em>
                        Excellent and discrete; able to easily, and safely
                        attach and remove the needle and inject with confidence,
                        in a painless situation.
                      </em>
                      &rdquo;
                    </p>
                    <p className="small text-primary">
                      <strong>Callum Scherbarth</strong>
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <p className="lead text-dark pb-2">
                      &ldquo;
                      <em>
                        The <strong>best pen-tip</strong> that i have used so
                        far since needing to use insulin.
                      </em>
                      &rdquo;
                    </p>
                    <p className="small text-primary">
                      <strong>Phoebe Ferguson</strong>
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Caption>
                    <p className="lead text-dark pb-2">
                      &ldquo;
                      <em>
                        They <strong>feel less painful</strong> when injecting
                        than other needles of the same length.
                      </em>
                      &rdquo;
                    </p>
                    <p className="small text-primary">
                      <strong>Warwick Sickling</strong>
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div id="order-samples-section" className="pt-5 pb-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2 className="text-primary" data-aos="fade">
                Now You Can Experience The Difference For Yourself!
              </h2>
            </div>
          </div>
          <div className="row pb-3 justify-content-center">
            <div className="col-md-10 text-center">
              <p className="lead text-dark" data-aos="fade">
                Unifine
                <sup>®</sup> Pentips
                <sup>®</sup> Plus is available on the NDSS but there is no need
                to rush out the door. If you send us your details we’ll send you
                some free samples to try!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <IframeResizer
                log={false}
                checkOrigin={false}
                src="https://forms.na2.netsuite.com/app/site/crm/externalleadpage.nl/compid.3923593/.f?formid=20&h=AACffht_OvfmEO1TLpQbs8Cl2hW9_NfYqOE&redirect_count=1&did_javascript_redirect=T&var=std"
                style={{ width: "1px", minWidth: "100%", border: "none" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="ask-your-pharmacy-section"
        className="pt-5 pb-5 bg-primary text-white"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2 data-aos="fade">Ask Your Pharmacy to Get Them In Now</h2>
              <div className="ndss-circle" data-aos="fade">
                <p>Available on the NDSS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="references-section" className="pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2 data-aos="fade" className="h5">
                References
              </h2>
              <ol className="text-left">
                <li>Melab GmbH Testing 2017.</li>
                <li>
                  Complete compatibility information available on{" "}
                  <a href="www.owenmumford.com" target="_blank">
                    owenmumford.com
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
